import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { events } from 'services/api';
import { LoadContainer } from './styled';

function Loading() {
  let [load, setLoadState] = useState(false);
  useEffect(() => {
    function handleLoadChange(load) {
      setLoadState(load);
    }
    events.on('onLoad', handleLoadChange);
    return () => {
      events.removeListener('onLoad', handleLoadChange);
    };
  });

  return (
    <LoadContainer id="load" loading={load.toString()}>
      <CircularProgress />
    </LoadContainer>
  );
}

export default Loading;
