import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from 'context';
import logo from 'components/base/svg/logo/5.svg';
import Userprofile from './Header/Header';
import './styles.sass';
import {
  alpha,
  makeStyles,
  List,
  Toolbar,
  useTheme,
  AppBar,
  Drawer,
  Tooltip,
  Grid,
  ListItemText,
  ListItemIcon,
  ListItem,
  IconButton,
  Divider,
  Typography,
  CssBaseline
} from '@material-ui/core';
import {
  MenuOpen,
  CardTravelOutlined,
  LayersOutlined,
  Group as GroupIcon,
  Dashboard as DashboardIcon,
  AccountBalanceWalletOutlined,
  CropSquare as PlanIcon
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import BusinessIcon from '@material-ui/icons/Business';
import DescriptionIcon from '@material-ui/icons/Description';

const drawerWidth = 265;
const StyledDrawer = styled(Drawer)`
  & > div {
    background-color: #091c3a;
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  grow: {
    flexGrow: 1
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  appBar: {
    // height: 'auto',
    zIndex: theme.zIndex.appBar,
    height: '60px',
    backgroundColor: 'white', //'#091c3a',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.down('lg')]: {
      height: '60px'
    },
    [theme.breakpoints.up('lg')]: {
      height: '60px'
    }
  },
  logoimage: {
    width: '45%',
    [theme.breakpoints.down('xs')]: {
      width: '60%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '70%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '50%'
    },
    [theme.breakpoints.between('md', 'ls')]: {
      width: '50%',
      height: '9%'
    }
  },
  header: {
    color: '#091c3a'
  },
  search: {
    position: 'relative',
    color: 'black',
    // backgroundColor: 'blue',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  activeLink: {
    color: '#99a6b7',
    marginTop: '15px'
  },
  buttonOpenDrawer: {
    paddingTop: '20px'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  appBarShift: {
    paddingLeft: '265px',
    height: '55px',
    paddingTop: '6px',
    minWidth: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  hiddenDrawer: {
    [theme.breakpoints.down('xs')]: {
      // opacity:'0',
      display: 'none'
    }
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  }
}));

export default function Layout() {
  let history = useHistory();
  const { auth, setAuth } = useAuth();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const items = [
    {
      icon: DashboardIcon,
      to: '/',
      label: 'Dashboard'
    },
    {
      icon: GroupIcon,
      to: '/contributors',
      label: 'Colaboradores'
    },
    {
      icon: LayersOutlined,
      to: '/departments',
      label: 'Departamentos'
    },
    {
      icon: CardTravelOutlined,
      to: '/trip',
      label: 'Parâmetros de viagem'
    },
    {
      icon: PlanIcon,
      to: '/planos',
      label: 'Planos'
    },
    {
      icon: AccountBalanceWalletOutlined,
      to: '/financial',
      label: 'Financeiro'
    },
    {
      icon: DescriptionIcon,
      to: '/report-trips',
      label: 'Relatório de viagens'
    },
    {
      icon: BusinessIcon,
      to: '/companies',
      label: 'Empresas',
      notShow: auth.roles.indexOf('master') === -1
    }
  ];

  const buttonLogoutHandler = () => {
    setAuth();
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const pushRoute = route => {
    return history.push(route);
  };

  const handleDrawerClose = () => {
    setOpen(!open);
  };

  const { name, roles, email } = auth;
  const usernamedetails = name && name.split(' ');
  const userProfileDetails = {
    userName: `${usernamedetails[0] && usernamedetails[0]} ${
      usernamedetails[usernamedetails && usernamedetails.length - 1]
    }`,
    email: email,
    userRole: roles || [],
    iconUrl: '',
    onClick: function onClick() {},
    visibility: {
      editProfile: false
    }
  };

  const hideDrawer = open ? '' : classes.hiddenDrawer;
  return (
    <Grid>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open
            })}
          >
            <MenuOpen style={{ color: '#091c3a' }} />
          </IconButton>
          <Typography variant="h6" noWrap>
            <Link className={classes.header} to="/">
              <img
                src={logo}
                alt="Turbi Empresas"
                className={classes.logoimage}
              />
            </Link>
          </Typography>

          {/* <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Pesquisar.."
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </div> */}

          <Grid container justifyContent="flex-end">
            {/* <IconButton aria-label="show 17 new notifications" color="inherit">
                                <Badge color="primary">
                                    <NotificationsIcon style={{ color: '#091c3a' }} />
                                </Badge>
                            </IconButton> */}
            <Userprofile
              UserCard={userProfileDetails}
              onLogoutClick={buttonLogoutHandler}
            />
          </Grid>
        </Toolbar>
      </AppBar>

      <StyledDrawer
        variant="permanent"
        className={clsx(classes.drawer, hideDrawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
      >
        <Grid>
          <ListItem
            button
            className={classes.buttonOpenDrawer}
            onClick={handleDrawerClose}
          >
            <ListItemIcon>
              {theme.direction === 'rtl' ? (
                <MenuOpen />
              ) : (
                <MenuOpen style={{ color: '#f2f2f2' }} />
              )}
            </ListItemIcon>
            <ListItemText style={{ color: '#99a6b7' }} />
          </ListItem>
        </Grid>
        <Divider />
        <List>
          {items
            .filter(i => !i.notShow)
            .map((item, index) => (
              <Link key={index} to={item.to} onClick={() => pushRoute(item.to)}>
                <Tooltip
                  style={{ fontFamily: 'ProximaNova-Regular' }}
                  className={classes.activeLink}
                  title={item.label}
                  aria-label={item.label}
                >
                  <ListItem button>
                    <ListItemIcon>
                      {<item.icon style={{ color: '#99a6b7' }} />}
                    </ListItemIcon>
                    <ListItemText
                      style={{
                        fontFamily: 'ProximaNova-Regular',
                        color: '#99a6b7'
                      }}
                      primary={item.label}
                    />
                  </ListItem>
                </Tooltip>
              </Link>
            ))}
        </List>
        <Divider />
        {/* {open? <List>
            <div className="header__footer">
                <UserNav
                {...auth}
                />
            </div>
        </List>:null} */}
      </StyledDrawer>
    </Grid>
  );
}
