import React, { useState, useEffect } from 'react';
import { Redirect } from "react-router-dom";
import jsonWebTokenService from 'jsonwebtoken';
import LoginTemplate from 'components/templates/Login';
import LoginService from 'services/Login';
import { getStorage } from 'store';
import { useAuth } from "context";
import CompanyService from 'services/company.service';

const LoginPage = props => {

  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const { setAuth, auth } = useAuth();

  useEffect(()=> {
    if(auth && (!(auth || {}).company)) {
      fetchCompany();
    }
  }, [auth])

  const fetchCompany = async () => {
    try {
      const { data } = await CompanyService.find();
      setAuth({...auth, company: data});
    } catch(error) {
      console.log(error);
    }
  }

  async function doSignIn(data) {
    const res = await LoginService.signIn(data);
    if (res.status === 200) {
      const decodedToken = jsonWebTokenService.decode(res.data.token);
      setAuth({
        expires: res.data.expire,
        token: res.data.token,
        exp: decodedToken.exp,
        orig_iat: decodedToken.orig_iat,
        ...decodedToken.user
      });
      setLoggedIn(true);
    } else {
      setIsError(true);
      setTimeout(() => {
        setIsError(false);
      }, 3000);
    }
  }

  if (getStorage() || isLoggedIn) {
    return <Redirect to="/" />
  }

  return (
    <LoginTemplate
      formSubmitHandler={doSignIn}
      formSubmitError={isError}
    />
  );
}

export default LoginPage;